<template>
    <div class="add box_b">
        <!--  -->
        <div class="add_main box_b">
            <div class="vue_title">
                <span @click="back"><i class="el-icon-arrow-left"></i>返回</span>
                <p> {{id?'修改活动':'添加活动'}}</p>
            </div>
            <!--  -->
            <div class="add_info box_b" v-loading="loading">
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>标题</div>
                    <div class="t_right">
                        <el-input v-model="info.title" size='small' maxlength="10" clearable placeholder="请输入活动标题"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>封面</div>
                    <div class="t_right">
                        <el-upload class="r_img" accept="image/jpg,image/jpeg,image/png,image/gif" action="" :before-upload="beforeAvatarUpload" :http-request="httpRequestMultiple" :show-file-list="false">
                            <div v-if="info.cover" class="w100 h100">
                                <img class="w100 h100" :src="info.cover" alt="">
                            </div>
                            <div class="r_img_no w100 h100" v-else>
                                <i class="el-icon-plus"></i>
                                <div>上传封面</div>
                            </div>
                        </el-upload>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>类型</div>
                    <div class="t_right_select">
                        <el-select v-model="info.type" size='small' placeholder="请选择活动类型">
                            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>描述</div>
                    <div class="t_right">
                        <el-input type="textarea" :rows="3" v-model="info.describe" size='small' clearable placeholder="请输入简单描述"></el-input>
                    </div>
                </div>
                <div class="info_text" style="align-items: center;">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>支付类型</div>
                    <div class="t_right_select">
                        <el-select v-model="info.pay_type" size='small' placeholder="请选择支付类型">
                            <el-option v-for="item in payList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="info_text" v-if="info.pay_type==3">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>价格</div>
                    <div class="t_right">
                        <el-input v-model="info.price" size='small' @input="limitNum($event,'price')" clearable placeholder="请输入价格"></el-input>
                    </div>
                </div>
                <div class="info_text" v-if="info.pay_type==3">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>划线价</div>
                    <div class="t_right">
                        <el-input v-model="info.marking_price" size='small' @input="limitNum($event,'marking_price')" clearable placeholder="请输入划线价"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>报名人数</div>
                    <div class="t_right">
                        <el-input v-model="info.people" size='small' @input="limitNum($event,'people')" clearable placeholder="请输入报名人数"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6">排序</div>
                    <div class="t_right">
                        <el-input v-model="info.sort" size='small' @input="limitNum($event,'sort')" clearable placeholder="请输入排序"></el-input>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6">置顶</div>
                    <div class="t_right" style="display: flex;align-items: center;">
                        <el-switch v-model="info.is_top" :active-value='1' :inactive-value='2' active-color="#2E4BF2" inactive-color="#999"></el-switch>
                    </div>
                </div>
                <div class="info_text">
                    <div class="t_left f_s2 color6"><span class="color1">*</span>简介</div>
                    <div class="edit">
                        <div style="border: 1px solid #DCDFE6;">
                            <Toolbar style="width: 100%;border-bottom: 1px solid #DCDFE6" :editor="editor" :defaultConfig="toolbarConfig" :mode="mode" />
                            <Editor style="height: 350px; width: 100%; overflow-y: hidden;" v-model="info.introduction" :defaultConfig="editorConfig" :mode="mode" @onCreated="onCreated" />
                        </div>
                    </div>
                </div>

            </div>
            <!--  -->
            <div class="dialog-footer">
                <el-button type="primary" size='small' @click="confirm" :loading="btnLoading">确 定</el-button>
                <el-button size='small' @click="chongzhi">重 置</el-button>
            </div>

        </div>
    </div>
</template>
  
  <script>
import '@wangeditor/editor/dist/css/style.css'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
export default {
    name: "notice_add",
    components: {
        Editor,
        Toolbar
    },
    data() {
        return {
            headers: {
                'Authorization': this.$store.state.token
            },
            loading: false,
            btnLoading: false,
            info: {
                title: "",
                cover: "",
                introduction: "",
                type: "",
                people: "",
                marking_price: "",
                price: "",
                describe: "",
                pay_type: "",
                sort:0,
                is_top:2,
            },
            id: "",
            editor: null,
            toolbarConfig: {},
            editorConfig: {
                placeholder: '请输入内容...',
                // 所有的菜单配置，都要在 MENU_CONF 属性下
                MENU_CONF: {
                    // 配置上传图片
                    uploadImage: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 配置上传视频
                    uploadVideo: {
                        server: '/admin/utils/upload_file',
                        customUpload: this.update
                    },
                    // 继续其他菜单配置...
                }
            },
            mode: 'default', // or 'simple'
            // 
            typeList: [
                {
                    label: '会员活动',
                    value: 1,
                },
                {
                    label: '训练营',
                    value: 2,
                },
                {
                    label: '流量大课',
                    value: 3,
                },
            ],
            payList: [
                {
                    label: '会员课程',
                    value: 1,
                },
                {
                    label: '免费课程',
                    value: 2,
                },
                {
                    label: '付费课程',
                    value: 3,
                },
            ],
        };
    },

    created() {
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },

    beforeDestroy() {
        const editor = this.editor
        if (editor == null) return
        editor.destroy() // 组件销毁时，及时销毁编辑器
    },
    methods: {
        // 富文本上传图片
        update(file, insertFn) {
            var that = this;
            let files = event.target.files[0];
            let form = new FormData();
            form.append("file", files);
            that.$publicApi.uploadFile(form).then(res => {
                if (res.code == 1000) {
                    insertFn(res.result.url, file.name, res.result.url)
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        confirm() {
            if (this.info.title == '') {
                this.$errMsg('请输入活动标题')
                return
            }
            if (this.info.cover == '') {
                this.$errMsg('请上传活动封面')
                return
            }
            if (this.info.type == '') {
                this.$errMsg('请选择活动类型')
                return
            }
            if (this.info.describe == '') {
                this.$errMsg('请输入活动描述')
                return
            }
            if (this.info.pay_type == '') {
                this.$errMsg('请选择支付类型')
                return
            }
            if (this.info.pay_type == 3 && this.info.price == '') {
                this.$errMsg('请输入活动价格')
                return
            }
            if (this.info.pay_type == 3 && this.info.marking_price == '') {
                this.$errMsg('请输入划线价格')
                return
            }
            if (this.info.people == '') {
                this.$errMsg('请输入报名人数')
                return
            }
            if (this.$testVerify.isContent(this.info.introduction)) {
                this.$errMsg('请输入活动简介')
                return
            }
            // 修改
            if (this.id) {
                this.edit()
            } else {
                // 添加
                this.add()
            }
        },
        // 添加
        add() {
            this.btnLoading = true;
            var params = {
                title: this.info.title,
                cover: this.info.cover,
                introduction: this.info.introduction,
                type: this.info.type,
                people: this.info.people,
                marking_price: this.info.pay_type == 1 ? 0 : this.info.pay_type == 2 ? 0 : this.info.marking_price,
                price: this.info.pay_type == 1 ? 0 : this.info.pay_type == 2 ? 0 : this.info.price,
                describe: this.info.describe,
                pay_type: this.info.pay_type,
                sort: this.info.sort,
                is_top: this.info.is_top
            }
            this.$activityApi.activityAdd(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/activity/list')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        edit() {
            this.btnLoading = true;
            var params = {
                title: this.info.title,
                cover: this.info.cover,
                introduction: this.info.introduction,
                type: this.info.type,
                people: this.info.people,
                marking_price: this.info.pay_type == 1 ? 0 : this.info.pay_type == 2 ? 0 : this.info.marking_price,
                price: this.info.pay_type == 1 ? 0 : this.info.pay_type == 2 ? 0 : this.info.price,
                describe: this.info.describe,
                pay_type: this.info.pay_type,
                sort: this.info.sort,
                is_top: this.info.is_top
            }
            this.$activityApi.activityEdit(params, this.id).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.btnLoading = false;
                    this.$router.push('/activity/list')
                } else {
                    this.$errMsg(res.message)
                    this.btnLoading = false;
                }
            })
        },
        getInfo() {
            this.loading = true;
            this.$activityApi.activityInfo(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = {
                        title: res.result.title,
                        cover: res.result.cover,
                        introduction: res.result.introduction,
                        type: res.result.type,
                        people: res.result.people,
                        marking_price: res.result.marking_price,
                        price: res.result.price,
                        describe: res.result.describe,
                        pay_type: res.result.pay_type,
                        sort: res.result.sort,
                        is_top: res.result.is_top
                    }
                    this.loading = false;
                } else {
                    this.loading = false;
                    this.$errMsg(res.message)
                }
            })
        },
        chongzhi() {
            this.info = {
                title: "",
                cover: "",
                introduction: "",
                type: "",
                people: "",
                marking_price: "",
                price: "",
                describe: "",
                pay_type: "",
                sort:0,
                is_top: 2,
            }
        },
        onCreated(editor) {
            this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
        },
        // 上传图片限制
        beforeAvatarUpload(file) {
            const isLt10KB = file.size / 1024 / 1024 < 10;
            let types = ['image/jpg', 'image/png', 'image/gif', 'image/jpeg'];
            const isType = types.includes(file.type);
            if (!isType) {
                this.$message.error('只能上传只 JPG/PNG/JEPG/GIF 格式!');
            }
            if (!isLt10KB) {
                this.$message.error('上传图片大小不能超过 10M!');
            }

            return isType && isLt10KB;
        },
        // 上传图片
        httpRequestMultiple(uploader) {
            const formData = new FormData();
            formData.set('file', uploader.file);
            this.$publicApi.uploadFile(formData).then(res => {
                if (res.code === 1000) {
                    this.info.cover = res.result.url
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 输入框限制
        limitNum(e, type) {
            if (type == 'price') {
                this.info.price = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'marking_price') {
                this.info.marking_price = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'people') {
                this.info.people = e.replace(/[^0-9.]/g, '')
            }
            if (type == 'sort') {
                this.info.sort = e.replace(/[^0-9]/g, '')
            }
        },
        back() {
            this.$router.back()
        },
    },
}
  </script>
  <style lang="scss" scoped>
// @import "../../assets/style/common.less";

.add {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    .add_main {
        width: 100%;
        height: 100%;
    }

    .sms_title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        padding-bottom: 10px;
        border-bottom: 1px solid #f4f5fa;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .add_info {
        width: 100%;
        height: calc(100% - 90px);
        padding: 15px 0;
        overflow-y: auto;

        .info_text {
            width: 100%;
            padding: 12px 0 0;
            overflow: hidden;
            display: flex;
            .switch {
                width: 90px;
                text-align: right;
                margin-right: 14px;
            }
            .edit {
                width: 1000px;
                margin-left: 10px;
                padding-top: 6px;
                position: relative;
                z-index: 1000;
            }
            .t_left {
                float: left;
                width: 110px;
                line-height: 32px;
                text-align: right;
            }
            .t_right {
                float: left;
                width: 500px;
                margin-left: 12px;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                .r_img {
                    width: 80px;
                    height: 80px;
                    cursor: pointer;
                    border: 1px solid #e8eaec;
                    background-color: #f5f7fa;
                    border-radius: 5px;
                    .r_img_no {
                        padding-top: 20px;
                        font-size: 14px;
                        color: #94979f;
                        i {
                            font-size: 18px;
                        }
                        div {
                            padding-top: 5px;
                        }
                    }
                }
                .r_txt {
                    padding-left: 10px;
                }
                .el-select {
                    width: 100%;
                }
            }
            .t_right_select {
                float: left;
                width: 500px;
                margin-left: 12px;
                .el-select {
                    width: 100%;
                }
            }
            .radio {
                padding-left: 10px;
                display: flex;
                align-items: center;
                height: 32px;
            }
        }
    }
}
.dialog-footer {
    padding-left: 50px;
    padding-bottom: 20px;
}
.box_b {
    box-sizing: border-box;
}
.f_s2 {
    font-size: 14px;
    line-height: 20px;
}
.w100 {
    width: 100%;
}
.h100 {
    height: 100%;
}
.color1 {
    color: #e51d41;
}

.color6 {
    color: #606266;
}
.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
::v-deep .el-upload {
    width: 100%;
    height: 100%;
}
</style>
  